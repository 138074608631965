import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import CoverLayout from "../components/CoverLayout"
import {HeadComponent} from "../components/HeadComponent"

import { main } from "./index.module.scss"

const IndexPage = ({ data }) => {
  const coverImage = {
      image: data.image.childImageSharp.gatsbyImageData,
      alt: "© - Milomon - https://www.instagram.com/milomon/"
  }
  return (
    <Layout chClassName={main}>
      
      <CoverLayout coverImage={coverImage}>
        <h2>#DATACITY</h2>
        <h3>La révolution des données</h3>
        <p>
          L'ouverture des données a radicalement changé l'approche des études
          urbaines, et notamment de l'analyse foncière. Aujourd'hui, les
          collectivités et aménageurs disposent de données très précises ouvrant
          des possibilités nouvelles pour l'observation et l'aide à la décision.
        </p>
        <hr />
        <h3>OpenData</h3>
        <p>
          Depuis quelques années, de nombreuses sources de données pertienentes
          pour l'analyse foncière ont été ouvertes au public. Il s'agit
          notamment:
          <ul>
            <li>
              du cadastre -{" "}
              <a
                href="https://cadastre.data.gouv.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://cadastre.data.gouv.fr/
              </a>
            </li>
            <li>
              des données DVF (Données sur les valeurs Foncières) -{" "}
              <a
                href="http://app.dvf.etalab.gouv.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                http://app.dvf.etalab.gouv.fr/
              </a>
            </li>
            <li>des données SIRENE sur les entreprises</li>
          </ul>
          Autour de l'aménagement et de l'urbanisme, citons également:
          <ul>
            <li>
              la base des PLU et Cartes Communales -{" "}
              <a
                href="https://www.geoportail-urbanisme.gouv.fr/"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://www.geoportail-urbanisme.gouv.fr/
              </a>
            </li>
            <li>
              les bases de données sur les risques (naturels, technologiques...)
              : PPRN, PPRI, Aléa Minier, ICPE, Seveso, Basias, Basol
            </li>
            <li>les nombreuses données mises à disposition par l'IGN</li>
          </ul>
          Ces données sont en Opendata : disponibles librement sur internet,
          avec une licence permettant leur réutilisation.
        </p>
        <h3>et autres données sous licence</h3>
        <p>
          KADATA possède une expertise dans le traitement de fichiers qui ne
          font pas l'objet de licence ouverte concernant le foncier.
          <br />
          Par exemple, la DGFiP met à disposition deux types de fichiers issus
          de la matrice cadastrale et du traitement des données sur les
          déclarations de taxes foncières et d'habitation:
          <ul>
            <li>
              les fichiers MAJIC donnant une information très précise sur la
              propriété
            </li>
            <li>
              les fichiers 1767bis.com destiné à l'analyse de la vacance habitat
            </li>
          </ul>
        </p>
        <p>
          Notre expertise nous permet d'apporter des solutions tant sur
          l'observation de la vacance, notamment dans le cadre de Programmes
          Locaux de l'Habitat (PLH), que dans le cadre de l'élaboration de
          stratégies foncières.
        </p>
      </CoverLayout>
    </Layout>
  )}
export const pageQuery = graphql`{
  image: file(relativePath: {eq: "milomon/BjFpk4clv4L.jpg"}) {
    childImageSharp {
      gatsbyImageData(
        width: 480
        tracedSVGOptions: {background: "#fff", color: "#007FE6"}
        placeholder: TRACED_SVG
        layout: CONSTRAINED
      )
    }
  }
}
`

export default IndexPage

export const Head = () => <HeadComponent title="Données foncières et Urbanisme" />